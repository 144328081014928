import React from 'react';
import Layout from '../components/Layout';
import Breadcrums from "../components/Breadcrums";

const Service_one = () => {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{backgroundImage: `url(/../images/levenmas/Nomina.jpg)`}}
                    >
                        <h1>Nómina</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <Breadcrums name="Nómina"/>

                        <div className="card">
                            <div className="card__content">
                                <p className="services_section_1__paragraph">
                                    Olvídate de la administración de la nómina, contamos con
                                    especialistas que te ayudarán a reducir Tiempo y Costos, y podrás
                                    concentrarte en la proyección de tu negocio. Elaboramos la nómina
                                    de acuerdo a las necesidades de tu empresa incluyendo los cálculos
                                    adicionales como Aguinaldo y PTU.
                                </p>
                                <h1 style={{color: '#000000', fontSize: 20}}>La nómina incluye entre otras cosas:</h1>
                                <ul style={{color: '#000000'}}>
                                    <li>Generación de recibos de nómina (xml , pdf)</li>
                                    <li>Altas, Bajas y Modificaciones ante el IMSS</li>
                                    <li>Calculo de riesgo</li>
                                    <li>Finiquitos y Liquidaciones</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Service_one;
